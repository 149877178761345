
<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-08-02 09:29:52
 * @Description: 政策通报列表
-->
<template>
  <div class="policyReportManagement">
    <ds-header title="政策通报管理"></ds-header>
    <a-form layout="inline" style="max-width: 1180px;">
      <a-form-item label="政策标题">
        <a-input v-model.trim="searchForm.policyTitle" placeholder="请输入政策标题"></a-input>
      </a-form-item>
      <a-form-item label="政策类型">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请输入政策类型"
          v-model="searchForm.policyTypeId"
        >
          <a-select-option value="">全部类型</a-select-option>
          <a-select-option v-for="item in policyType" :key="item.policyTypeId" :value="item.policyTypeId">
            {{ item.policyTypeName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="所属项目：">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectId"
        >
          <a-select-option value="">全部项目</a-select-option>
          <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectName"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
        <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择所属项目" />
      </a-form-item>
      <!-- <a-form-item label="是否弹框提醒：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.policyPopup"
        >
          <a-select-option v-for="item in isPolicyPopup" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item> -->
      <a-form-item label="所属模块">
        <a-input v-model.trim="searchForm.moduleName" placeholder="请输入所属模块"></a-input>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.policyPublishedStatus"
        >
          <a-select-option v-for="item in publishStatus" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>
    <div style="margin: 10px 0">
      <a-button type="primary" @click="addPolicy()"> <a-icon type="plus" /> 新增通报政策 </a-button>
    </div>
    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500,y: height }"
    >
      <div class="title" slot="policyTitle" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.policyTitle }}</span>
          </template>
          <div>
            {{ text.policyTitle }}
          </div>
        </a-tooltip>
      </div>
      <div slot="projectName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.projectName }}</span>
          </template>
          <div
            :style="{
              width: '220px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.projectName }}
          </div>
        </a-tooltip>
      </div>
      <div slot="moduleNameStr" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.moduleNameStr }}</span>
          </template>
          <div
            :style="{
              width: '220px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.moduleNameStr?text.moduleNameStr:'-' }}
          </div>
        </a-tooltip>
      </div>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="isPublish(record)" class="btn"
          >{{ record.policyPublishedStatus === 0 ? "取消发布" : "发布" }}
        </a-button>
        <a-button type="link" @click="toDetail(record)" class="btn"> 查看 </a-button>
        <a-button type="link" @click="edit(record)" class="btn"> 编辑 </a-button>
        <a-button type="link" @click="deletePolicy(record)" style="color: #f5222d" class="btn"> 删除 </a-button>

        <a-button type="link" @click="dateDetail(record)" class="btn"> 数据统计 </a-button>
      </span>
    </a-table>
    <a-modal v-model="isShowDialog" title="数据统计" width="800px" v-if="isShowDialog" @ok="closeDataStatistics">
      <DataStatistics :policyId="policyId"></DataStatistics>
    </a-modal>
  </div>
</template>

<script>
import {
  policyReportList,
  policyReportDelete,
  policyReportPublish,
  getProjectList,
  getPolicyType,
  getShunyiAllProject
} from "@/api/entrepreneurClubManagement/policyManagement";
import pageRules from "@/utils/pagingConfig/pageUtils.js";
import DataStatistics from "./dataStatistics.vue";

export default {
  name: "policyReportManagement",
  data() {
    return {
      // 查询条件
      searchForm: {
        // 政策标题
        policyTitle: "",
        // 政策类型
        policyTypeId: "",
        // 所属项目
        projectId: [],
        // 是否弹窗提醒
        policyPopup: "",
        // 状态
        policyPublishedStatus: "",
        moduleName:'',// 所属模块
      },
      // 是否弹窗提醒
      isPolicyPopup: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 0,
          label: "是",
        },
        {
          value: 1,
          label: "否",
        },
      ],
      // 状态
      publishStatus: [
        {
          value: "",
          label: "全部状态",
        },
        {
          value: 0,
          label: "已发布",
        },
        {
          value: 1,
          label: "未发布",
        },
      ],
      // 所属项目
      projectName: [],
      table: {
        columns: [
          {
            title: "政策标题",
            key: "policyTitle",
            width: 250,
            scopedSlots: { customRender: "policyTitle" },
          },
          {
            title: "政策类型",
            key: "policyTypeName",
            dataIndex: "policyTypeName",
            width: 180,
          },
          {
            title: "浏览总次数",
            dataIndex: "viewCount",
            key: "viewCount",
            width: 120,
          },
          // {
          //   title: "是否弹框提醒",
          //   dataIndex: "policyPopup",
          //   key: "policyPopup",
          //   width: 120,
          //   customRender(text) {
          //     return text == 1 ? "否" : "是";
          //   },
          // },
          {
            title: "状态",
            dataIndex: "policyPublishedStatus",
            key: "policyPublishedStatus",
            width: 120,
            customRender(text) {
              return text == 1 ? "未发布" : "已发布";
            },
          },
          {
            title: "所属项目",
            key: "projectName",
            width: 250,
            scopedSlots: { customRender: "projectName" },
          },
          {
            title: "所属模块",
            key: "moduleNameStr",
            width: 250,
            scopedSlots: { customRender: "moduleNameStr" },
          },
          {
            title: "创建人",
            dataIndex: "createIdShow",
            key: "createIdShow",
            width: 150,
          },
          {
            title: "发布时间",
            dataIndex: "publishTime",
            key: "publishTime",
            width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },
          {
            title: "创建时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "操作",
            key: "action",
            width: 280,
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
            fixed: 'right',
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 是否展示数据统计
      isShowDialog: false,
      // 政策ID
      policyId: "",
      // 政策类型
      policyType: [],
      height:''
    };
  },
  components: {
    DataStatistics,
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["policyReportManagement"]);
      if (from.query && from.query.id) {
        vm.getList();
      }
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportDetail" &&
      to.path !== "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportAdd"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    } else if (
      to.path === "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportAdd" &&
      to.query.type === "add"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  mounted() {
    // 获取数据列表
    this.getList();
    // 获取所属项目
    this.getProject();
    // 政策类型
    this.getPolicyType();
    let handleResize = ()=> {
      this.height = window.innerHeight-341-64-40-30
      let width = document.getElementsByClassName('policyReportManagement')[0].clientWidth;
      if(width>1900){
        this.table.columns[this.table.columns.length-1].fixed = '';
      }else{
        this.table.columns[this.table.columns.length-1].fixed = 'right';
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize()

  },
  methods: {
    // 查询
    search() {
      this.table.pagination.pageSize = 10;
      this.table.pagination.current = 1;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 政策标题
        policyTitle: "",
        // 政策类型
        policyTypeId: "",
        // 所属项目
        projectId: [],
        // 是否弹窗提醒
        policyPopup: "",
        // 状态
        policyPublishedStatus: "",
        moduleName:''
      };
      this.table.pagination.pageSize = 10;
      this.table.pagination.current = 1;
      this.getList();
    },
    // 获取列表数据
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await policyReportList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        }
      } catch (error) {
        this.$message.error("网络错误");
      }
    },
    // 新增通报政策
    addPolicy() {
      this.$router.push({
        path: "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportAdd",
        query: {
          type: "add",
        },
      });
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 编辑
    edit(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportAdd",
        query: {
          type: "edit",
          id: record.policyId,
        },
      });
    },
    // 删除
    deletePolicy(record) {
      const _this = this;
      _this.$confirm({
        title: "确认删除",
        content: "删除后该数据无法恢复，确认删除吗？",
        async onOk() {
          try {
            const res = await policyReportDelete(record.policyId);
            if (res.code === "200") {
              _this.table.pagination.current = pageRules.pageRules(
                _this.table.tableData.length,
                1,
                _this.table.pagination.current
              );
              _this.$message.success("删除成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {
            _this.$message.error("删除失败");
          }
        },
        onCancel() {},
      });
    },
    // 查看
    toDetail(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/policyManagement/policyReportManagement/policyReportDetail",
        query: {
          id: record.policyId,
        },
      });
    },
    // 发布/取消发布
    isPublish(record) {
      const _this = this;
      let title;
      let content;
      let policyPublishedStatus = 0;
      if (record.policyPublishedStatus === 1) {
        title = "确认发布该政策";
        content = "";
        policyPublishedStatus = 0;
      } else {
        title = "确认取消发布该政策";
        content = "";
        policyPublishedStatus = 1;
      }
      _this.$confirm({
        title: title,
        content: content,
        async onOk() {
          try {
            const res = await policyReportPublish(record.policyId, policyPublishedStatus);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 数据统计
    dateDetail(record) {
      this.policyId = record.policyId;
      this.isShowDialog = true;
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await getShunyiAllProject();
        if (res.code === "200") {
          this.projectName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 获取政策类型
    async getPolicyType() {
      try {
        const res = await getPolicyType();
        if (res.code === "200") {
          this.policyType = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 关闭弹窗
    closeDataStatistics() {
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
.title {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}
::v-deep .ant-table-thead>tr>th {
  padding: 8px 16px;
}

::v-deep .ant-table-tbody>tr>td {
  padding: 8px 16px;
}
</style>